body {
  font-family: 'Inter' !important;
}

.MuiTablePagination-root {
  padding-left: 0 !important;
  border: none !important;
}

.MuiTablePagination-toolbar {
  padding-left: 0 !important;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #e5e7e8;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #bdbdbd;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #868686;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* hi */
